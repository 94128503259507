<template>
  <div class="support">
    <card-support />
  </div>
</template>

<script>
import CardSupport from '@/components/cards/support/CardSupport.vue';

export default {
  name: 'Support',
  components: { CardSupport },
};
</script>

<style lang="scss" scoped>
.support {
  background: $dashboard_background;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
