<template>
  <div class="card-support">
    <div class="support-title">
      <h1>{{ $t('components.support.title') }}</h1>
    </div>

    <div class="form-search-support">
      <v-form>
        <v-text-field
          flat
          background-color="text-color"
          append-icon="mdi-magnify"
          class="input-search"
          :label="$t('components.support.form_search_support.input_search.placeholder')"
          solo
        />
      </v-form>
    </div>

    <div class="content-info">
      <div class="title-info">
        {{ $t('components.support.content_info.title') }}
      </div>
      <div class="subtitle-info">
        {{ $t('components.support.content_info.subtitle') }}
      </div>
    </div>

    <div class="content-cards-contact">
      <div
        class="card-contact mb-4"
        @click="goToWhatsapp()"
      >
        <div class="card-contact-icon">
          <v-icon
            size="40"
            class="icon-contact mb-6"
          >
            mdi-whatsapp
          </v-icon>
        </div>
        <div class="card-contact-text">
          {{ $t('components.support.cards.whatsapp') }}
        </div>
      </div>
      <div class="content-mail">
        <div
          class="card-contact"
          @click="showMail()"
        >
          <div class="card-contact-icon">
            <v-icon
              size="40"
              class="icon-contact mb-6"
            >
              mdi-at
            </v-icon>
          </div>
          <div class="card-contact-text">
            {{ $t('components.support.cards.email.title') }}
          </div>
        </div>
        <v-expand-transition>
          <div
            v-show="mail"
            class="show-contact"
          >
            <div>{{ $t('components.support.cards.email.recmais.title') }}</div>
            <div class="mail">
              {{ $t('components.support.cards.email.recmais.mail') }}
            </div>
            <v-divider />
          </div>
        </v-expand-transition>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardSupport',
  data() {
    return {
      mail: false,
    };
  },
  methods: {
    goToWhatsapp() {
      window.open('https://wa.me/6798247642', '_blank');
    },
    showMail() {
      this.mail = !this.mail;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-support {
  margin: 0px 140px 0px 340px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .support-title {
    margin: 70px 0 40px 0;
  }

  .form-search-support {
    width: 100%;

    .input-search {
      border-radius: 8px;
      max-width: 900px;
      margin: auto;
    }
  }

  .content-info {
    width: 100%;
    text-align: center;

    .title-info {
      max-width: 900px;
      margin: auto;
      font-size: 1.2em;
      font-weight: bold;
    }

    .subtitle-info {
      max-width: 900px;
      margin: auto;
      font-size: 1em;
      margin-top: 16px;
      color: $color_gray;
    }
  }

  .content-cards-contact {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 60px;

    .card-contact {
      background: $color_white;
      box-shadow: 0px 4px 16px $box_shadow_input_document;
      font-size: 1.25em;
      padding: 10px;
      cursor: pointer;
      font-weight: bold;
      width: 240px;
      height: 176px;
      border-radius: 10px;
      display: flex;
      margin: 0 54px;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      transition: 100ms;
    }

    .show-contact {
      background: $color_white;
      box-shadow: 0px 4px 16px $box_shadow_input_document;
      font-size: 0.8em;
      font-weight: bold;
      width: 240px;
      padding: 20px;
      margin: 32px 54px;
      max-width: 100%;
      color: black;
      border-radius: 10px;

      .mail {
        margin-top: 12px;
        color: $color_primary;
      }
    }

    .card-contact:hover {
      color: $color_primary;

      .card-contact-icon {
        .icon-contact {
          color: $color_primary;
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .card-support {
    margin: 8px;
  }
}
</style>
